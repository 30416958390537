<template>
    <v-container grid-list-xl fluid>
        <h1>Rastreamento de pacote</h1>
        <v-divider class="my-4" />
        <v-layout wrap v-if="resultMode == false">
            <v-flex xs12 sm6>
                <v-text-field :label="$t('Código de barras')" v-model="filter.ticket" clearable outlined hide-details v-on:keyup.enter="search('ticket')"
                    append-icon="qr_code_scanner" append-outer-icon="mdi-send" @click:append="openReadQRCode('ticket')" @click:append-outer="search('ticket')" />
            </v-flex>

            <v-flex xs12 sm6>
                <v-text-field :label="$t('Código de terceiro')" v-model="filter.code" clearable outlined hide-details v-on:keyup.enter="search('code')"
                    append-icon="qr_code_scanner" append-outer-icon="mdi-send" @click:append="openReadQRCode('code')" @click:append-outer="search('code')" />
            </v-flex>

            <v-flex xs12 sm6>
                <v-text-field :label="$t('nFe')" v-model="filter.nFe" clearable outlined hide-details v-on:keyup.enter="search('nFe')" append-icon="qr_code_scanner"
                    append-outer-icon="mdi-send" @click:append="openReadQRCode('nFe')" @click:append-outer="search('nFe')" />
            </v-flex>

            <v-flex xs12 sm6>
                <v-text-field :label="$t('Código do pedido')" v-model="filter.order" clearable outlined hide-details v-on:keyup.enter="search('order')"
                    append-icon="qr_code_scanner" append-outer-icon="mdi-send" @click:append="openReadQRCode('order')" @click:append-outer="search('order')" />
            </v-flex>

            <v-flex xs12>
                <v-text-field :label="$t('Chave CTE')" v-model="filter.key" clearable outlined hide-details v-on:keyup.enter="search('key')" append-icon="qr_code_scanner"
                    append-outer-icon="mdi-send" @click:append="openReadQRCode('key')" @click:append-outer="search('key')" />
            </v-flex>
            <v-flex xs12>
                <v-btn @click="search" color="success" width="100%">
                    <v-icon left>search</v-icon>Buscar
                </v-btn>
            </v-flex>
            <v-flex xs12>
                <v-alert outlined color="info" prominent border="top" icon="school">
                    <strong>Busca de pacote</strong>: <br><br> - A busca leva em consideração todos os campos digitados. <br> - Mais de um pacote pode ser retornado.
                </v-alert>
            </v-flex>
        </v-layout>
        <v-layout wrap v-else>
            <v-flex xs12>
                <v-btn @click="clearResult" color="error" width="100%" small>
                    <v-icon left>search</v-icon>Fazer outra busca
                </v-btn>
            </v-flex>
        </v-layout>

        <div class="ma-4 text-center" v-if="loaded == false">
            <v-progress-circular size="140" width="20" color="primary" indeterminate />
        </div>
        <v-expansion-panels v-model="panel" v-else>
            <v-expansion-panel v-for="( item, i ) in items" :key="i">
                <v-expansion-panel-header class="grey white--text mb-4">
                    {{ item.ticket ? `Código de barra: ${item.ticket}` : null }}
                    {{ item.key ? `Chave CTE: ${item.key}` : null }}
                    <template v-slot:actions>
                        <v-icon color="white">
                            $expand
                        </v-icon>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-layout wrap>

                        <v-flex xs12>
                            <h3>Status</h3>
                            <v-divider />
                        </v-flex>

                        <v-flex xs12 sm3 v-for="(value, key, index) in packagesStatus" :key="index">
                            <v-btn @click="changeStatus(item, key)" :outlined="item.status != key" :color="item.status == key ? 'success' : 'grey'" width="100%" small>
                                {{ value }}
                            </v-btn>
                        </v-flex>

                        <v-flex xs12>
                            <v-divider />
                        </v-flex>

                        <v-flex xs12 text-center>
                            <v-chip class="ma-0" outlined color="error" v-if="!item.collectedBy">
                                Ainda não coletado
                            </v-chip>
                            <v-chip class="ma-0" outlined color="error" v-if="!item.receivedBy">
                                Ainda não recebido
                            </v-chip>
                        </v-flex>

                        <v-flex xs12 v-if="item.collectedDate && $moment.duration($moment().utc().diff($moment(item.collectedDate))).asDays() >= 4">
                            <v-alert icon="error" prominent text dense type="error">Pedido já foi coletado a mais de 4 dias e deve ser devolvido e não mais ser feito tentativa de
                                entrega.</v-alert>
                        </v-flex>

                        <v-flex xs12 v-if="item.client">
                            <v-card color="primary" class="mx-auto mt-1">
                                <v-card-text class="white--text">
                                    <v-flex xs12 class="text-center">
                                        <b>Cliente</b>
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Nome:</b> {{ item.client.name }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Endereço:</b> {{ item.client.address }}
                                    </v-flex>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12>
                            <v-card color="primary" class="mx-auto mt-1">
                                <v-card-text class="white--text">
                                    <v-flex xs12 class="text-center">
                                        <b>Dados do pacote</b>
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Identificador:</b> {{ item.id }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Chave CTE:</b> {{ item.key }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.ticket">
                                        <b>Código barras:</b> {{ item.ticket }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.nFe">
                                        <b>nFe:</b> {{ item.nFe }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.order">
                                        <b>Código pedido:</b> {{ item.order }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.code">
                                        <b>Código de terceiro:</b> {{ item.code }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.volumeCode">
                                        <b>Código volume:</b> {{ item.volumeCode }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.idVuupt">
                                        <b>ID Vuupt:</b> {{ item.idVuupt }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Criado na fase:</b> {{ item.createMode }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.createdBy">
                                        <b>Criado por:</b> {{ item.createdBy }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Integração:</b> {{ item.integrationControl }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.createdAt">
                                        <b>Criado:</b> {{ $moment(item.createdAt).format("DD/MM/YY HH:mm") }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.updatedAt">
                                        <b>Última atualização:</b> {{ $moment(item.updatedAt).format("DD/MM/YY HH:mm") }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.necCreatedAt">
                                        <b>Criado(NEC):</b> {{ $moment(item.necCreatedAt).format("DD/MM/YY HH:mm") }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.necUpdatedAt">
                                        <b>Última atualização(NEC):</b> {{ $moment(item.necUpdatedAt).format("DD/MM/YY HH:mm") }}
                                    </v-flex>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12>
                            <v-card :color="item.sendCollected ? 'success' : 'error'" class="mx-auto mt-1" v-if="item.collectedBy">
                                <v-card-text class="white--text">
                                    <v-flex xs12 class="text-center">
                                        <b>Coletado</b>
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Data:</b> {{ $moment(item.collectedDate).format("DD/MM/YY HH:mm") }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Coletado por:</b> {{ item.collectedBy }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Romaneio:</b> {{ item.checklistCollect }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Enviado (NEC):</b> {{ item.sendCollected ? "Sim" : "Não" }}
                                    </v-flex>
                                    <v-flex xs12 v-if="isAdmin">
                                        <v-btn @click="sendNEC('collect', item.id, item.key, item.checklistCollect)" :color="item.sendCollected ? 'white' : 'success'" width="100%" small>
                                            <v-icon left>sync</v-icon>Forçar envio NEC
                                        </v-btn>
                                    </v-flex>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12>
                            <v-card :color="item.sendReceived ? 'success' : 'error'" class="mx-auto mt-1" v-if="item.receivedBy">
                                <v-card-text class="white--text">
                                    <v-flex xs12 class="text-center">
                                        <b>Recebido</b>
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Data:</b> {{ $moment(item.receivedDate).format("DD/MM/YY HH:mm") }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Recebido por:</b> {{ item.receivedBy }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Romaneio:</b> {{ item.checklistReceive }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.driverCollectPack">
                                        <b>Motorista:</b> {{ item.driverCollectPack }}
                                    </v-flex>
                                    <v-flex xs12 v-if="item.driverCollectPackExternal">
                                        <b>Motorista tercerizado:</b> {{ item.driverCollectPackExternal }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Enviado (NEC):</b> {{ item.sendReceived ? "Sim" : "Não" }}
                                    </v-flex>
                                    <v-flex xs12 v-if="isAdmin">
                                        <v-btn @click="sendNEC('receive', item.id, item.key, item.checklistReceive)" :color="item.sendReceived ? 'white' : 'success'" width="100%" small>
                                            <v-icon left>sync</v-icon>Forçar envio NEC
                                        </v-btn>
                                    </v-flex>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12>
                            <v-card :color="returnItem.sendDevolution ? 'success' : 'error'" class="mx-auto mt-1" v-for="(returnItem, index) in item.returnList" :key="index">
                                <v-card-text class="white--text">
                                    <v-flex xs12 class="text-center">
                                        <b>Insucesso: {{ index + 1 }} / {{ item.returnList.length }}</b>
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Data:</b> {{ $moment(returnItem.date).format("DD/MM/YY HH:mm") }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Recebido por:</b> {{ returnItem.createdBy }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Romaneio:</b> {{ returnItem.checklist }}
                                    </v-flex>
                                    <v-flex xs12 v-if="returnItem.driver">
                                        <b>Motorista:</b> {{ returnItem.driver }}
                                    </v-flex>
                                    <v-flex xs12 v-if="returnItem.driverExternal">
                                        <b>Motorista tercerizado:</b> {{ returnItem.driverExternal }}
                                    </v-flex>
                                    <v-flex xs12>
                                        <b>Enviado (devolução):</b> {{ returnItem.sendDevolution ? "Sim" : "Não" }}
                                    </v-flex>
                                    <v-flex xs12 v-if="isAdmin">
                                        <v-btn @click="sendNEC('return', returnItem._id, item.key, returnItem.checklist)" :color="returnItem.sendDevolution ? 'white' : 'success'" width="100%" small>
                                            <v-icon left>sync</v-icon>Forçar envio NEC
                                        </v-btn>
                                    </v-flex>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />
        <BarCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import BarCode from "@/components/core/BarCode";
import packagesStatus from "@/assets/json/packagesStatus.json";
import { mapGetters } from "vuex";

export default {
    components: { ConfirmModal, BarCode },

    data() {
        return {
            type: null,
            showQRCode: false,
            filter: {
                ticket: null,
                key: null,
                nFe: null,
                code: null,
                order: null,
            },
            resultMode: false,
            isAdmin: false,
            items: null,
            panel: null,
            loaded: true,
            packagesStatus
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ADMIN");
    },

    computed: {
        ...mapGetters(["verifyRole"])
    },

    methods: {
        sendStatus(item, status) {
            this.$http
                .patch(`packages/${item.id}`, { data: { status } })
                .then(() => {
                    this.$eventHub.$emit("msgSuccess", "Enviado com sucesso.");
                    this.search();
                })
                .catch(() => {
                    this.$eventHub.$emit("msgError", "Erro ao enviar notificação para NEC.");
                });
        },
        changeStatus(item, status) {
            if (item.status) {
                this.$refs.confirm.open("", `Pacote já possui o status de "${this.packagesStatus[status]}", deseja realmente alterar?`).then((confirm) => {
                    if (confirm) {
                        this.sendStatus(item, status);
                    }
                });
            } else {
                this.sendStatus(item, status)
            }
        },
        clearResult() {
            this.resultMode = false;
            this.items = null;
        },
        openReadQRCode(type) {
            this.type = type;
            this.showQRCode = true;

        },
        readedQRCode(QRCode) {
            if (this.type != null && this.filter[this.type]) {
                this.filter[this.type] = QRCode;
            }
            this.search();
        },
        sendNEC(type, id, key, checklist) {
            this.$refs.confirm.open("", "Deseja mesmo forçar o envio? Ação não poderá ser desfeita.").then((confirm) => {
                if (confirm) {
                    this.$http
                        .patch(`tracking/${type}/force/${id}`, { data: { key, checklist } })
                        .then(() => {
                            this.$eventHub.$emit("msgSuccess", "Enviado com sucesso.");
                            this.search();
                        })
                        .catch(() => {
                            this.$eventHub.$emit("msgError", "Erro ao enviar notificação para NEC.");
                        });
                }
            });
        },
        async forceGetPack(type) {
            console.log(type)
            if (type != undefined) {
                console.log(this.filter[type])
                if (this.ticket != null || this.filter[type]) {
                    this.$refs.confirm.open("", "Deseja forçar uma busca desse pacote na integração do NEC?").then((confirm) => {
                        if (confirm) {
                            this.$http.post(`integrations`, { [type]: this.filter[type] })
                                .then(() => {
                                    this.$eventHub.$emit("msgSuccess", "Pacote integrado com sucesso.");
                                })
                                .catch(() => {
                                });
                        }
                    });
                }
            }
        },
        async search(type) {
            this.showQRCode = false;
            this.items = null;
            this.panel = null;
            this.loaded = false;

            await this.$http
                .post(`tracking/get`, this.filter)
                .then((result) => {
                    if (result) {
                        this.items = result;
                        if (this.items.length == 1) {
                            this.panel = 0;
                        }
                        if (this.items.length > 0) {
                            this.resultMode = true;
                        }
                    } else {
                        this.$eventHub.$emit("msgError", "Pedido não localizado");
                        this.forceGetPack(type)
                    }
                })
                .catch((e) => {
                    this.$eventHub.$emit("msgError", e.message ? e.message : "Erro ao buscar.");
                    this.forceGetPack(type)
                }).finally(() => this.loaded = true);
        },
    },
};
</script>